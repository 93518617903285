import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'iteminfo',
    loadChildren: () => import('./iteminfo/iteminfo.module').then(m => m.IteminfoPageModule)
  },
  {
    path: 'shoppingcart',
    loadChildren: () => import('./shoppingcart/shoppingcart.module').then(m => m.ShoppingcartPageModule)
  },
  {
    path: 'orderfinished',
    loadChildren: () => import('./orderfinished/orderfinished.module').then( m => m.OrderfinishedPageModule)
  },
  {
    path: 'orderoverview',
    loadChildren: () => import('./orderoverview/orderoverview.module').then( m => m.OrderoverviewPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { of } from 'rxjs'
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpService {
  private httpWithoutInterceptor: HttpClient;

  constructor(private httpClient: HttpClient, private httpBackend: HttpBackend) { this.httpWithoutInterceptor = new HttpClient(httpBackend); }

/*   async getClientIp() {
    return this.httpClient.disableApiPrefix()
      .get<any>('http://ip.jsontest.com/')
      .pipe(
        map((response: any) => {
          console.log(response);
          return response;
        }),
        catchError((error: any) => of(error))
      );
  } */

  getClientIp() {
    return this.httpWithoutInterceptor
      .get<any>('https://api.bigdatacloud.net/data/client-ip')
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => of(error))
      );
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { IpService } from 'src/app/services/ip/ip.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(private ipService: IpService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.ipService.getClientIp().pipe(
      switchMap(ipObj => {
        const requestClone = request.clone({
          url: environment.serverUrl + request.url,
          setHeaders: { 'x-real-ip': ipObj.ipString }
        });
        return next.handle(requestClone);
      })
    ))
  }
}
